import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h3 className="title">
                      GDPR Compliance at Black & White Media
                    </h3>
                  </div>
                  <h4>Commitment to Data Privacy</h4>
                  <p>
                    At Black & White Media, respecting and protecting your data
                    privacy is at the core of our operations. We understand the
                    significance of personal data in the digital age and are
                    committed to adhering to the General Data Protection
                    Regulation (GDPR), ensuring transparent, interconnected
                    services that prioritize your privacy and data security.
                  </p>
                  <h4>Our Approach to Personal Data</h4>
                  <p>
                    We collect personal data to enhance our services and provide
                    you with a personalized experience across our design,
                    development, and SEO services. From initiating a new
                    campaign to optimizing your online presence, our use of
                    personal data is always aimed at delivering value and
                    excellence to your business.
                  </p>
                  <h4>Why We Collect Personal Data</h4>
                  <ul>
                    <li>
                      To Understand Your Needs: Analyzing your requirements to
                      offer tailored solutions..
                    </li>
                    <li>
                      To Communicate Effectively: Keeping you informed about new
                      opportunities and services.
                    </li>
                    <li>Whether you’re kicking off a new campaign.</li>
                    <li>Habitasse per feugiat aliquam luctus accumsan curae</li>
                  </ul>
                  <h4>Types of Data Collected</h4>
                  <ul>
                    <li>
                      Contact Information: To facilitate communication and
                      service delivery.
                    </li>
                    <li>
                      Usage Data: Information on how you interact with our
                      services, helping us to tailor and improve your
                      experience.
                    </li>
                    <li>
                      Automatic Information: Via cookies and web beacons, we
                      collect data to understand site usage patterns, improve
                      site functionality, and secure our services against
                      misuse.
                    </li>
                  </ul>
                  <h4>Cookies and Tracking Technologies</h4>
                  <p className="mb--20">
                    We employ cookies and web beacons to improve your experience
                    on our site, tailor our interactions with you, and for
                    security purposes. These technologies are used to understand
                    site usage, enhance site functionality, and protect against
                    security threats.
                  </p>
                  <h4>Data Retention</h4>
                  <p className="mb--20">
                    We retain personal data as long as necessary to fulfill the
                    purposes for which it was collected, address your inquiries,
                    resolve issues, and comply with legal obligations. Our
                    retention practices are designed to respect your rights and
                    address any legal or security requirements.
                    <br />
                    <br />
                    <strong>Your Rights Over Your Data:</strong> You have the
                    right to access, correct, or delete your personal data held
                    by us. We are committed to facilitating these rights,
                    ensuring you have control over your information in
                    accordance with GDPR requirements.
                    <br />
                    <br />
                    <strong>Changes to Our Privacy</strong>
                    Practices We may update this section to reflect changes in
                    our practices or legal obligations. We encourage you to
                    review this section periodically to stay informed about how
                    we are protecting your data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
