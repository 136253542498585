import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const Datas = [
  {
    id: 1,
    title: "Exceeding Expectations",
    para: "We aim to surpass what's expected of us in every project. By blending creativity with precision, we deliver results that not only meet but exceed the aspirations of our clients and colleagues. Our dedication is to excellence, ensuring that every detail is polished to perfection.",
  },
  {
    id: 2,
    title: "Ownership and Innovation",
    para: "We challenge the status quo with a constructive mindset, embracing responsibility for our actions and decisions. Our team is encouraged to think outside the box, leading to innovative solutions that set our work apart in the digital landscape.",
  },
  {
    id: 3,
    title: "Courage to Explore",
    para: "Bravery fuels our pursuit of knowledge. We experiment boldly, viewing every outcome as a learning opportunity. Successes guide us, and failures teach us, fostering an environment where growth and innovation thrive.",
  },
  {
    id: 4,
    title: "Integrity and Pride",
    para: "Our actions reflect our integrity. We conduct ourselves in a manner that makes us all proud, adhering to the highest standards of professionalism and ethical conduct. This commitment to integrity is what builds lasting relationships with our clients and within our team.",
  },
  {
    id: 5,
    title: "Inclusion, Transparency, and Responsibility",
    para: "We're dedicated to creating an environment where everyone feels valued and included. Our culture is built on transparency and responsibility, aiming to make a positive impact in the community and the world. We believe in openness, sharing our successes and challenges alike, to foster trust and collaboration.",
  },
];

const AboutThree = () => {
  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle="Our Valus"
          title="Why should you work with us?"
          description="At Black & White Media, we don't just offer services; we forge partnerships that are built on trust, innovation, and excellence. Our commitment to your success is driven by our core values, which are the foundation of everything we do."
          textAlignment="heading-left heading-light-left mb--100"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4" key={data.id}>
              <div className="about-quality">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
