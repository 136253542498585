import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormOne = () => {
  const form = useRef();

  const [result, showResult] = useState(false);
  const [templateParams, setFormData] = useState({
    from_name: "",
    contact_email: "",
    contact_phone: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_qd3q46l",
        "template_krtzgd2",
        templateParams,
        "UpBN0IWb6iVQFzUiy"
      )
      .then(
        (result) => {
          console.log(result.text);
          showResult(true);

          setFormData({
            from_name: "",
            contact_email: "",
            contact_phone: "",
          });
          setTimeout(() => {
            showResult(false);
          }, 5000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="from_name"
          value={templateParams.from_name}
          placeholder="Name"
          required
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="contact_email"
          placeholder="example@mail.com"
          required
          value={templateParams.contact_email}
          onChange={handleChange}
        />
      </div>
      <div className="form-group mb--40">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="contact_phone"
          placeholder="+123456789"
          required
          value={templateParams.contact_phone}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Get Free Quote
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormOne;
