import React from "react";

const Data = [
  {
    image: "/images/others/BOE.webp",
  },
  {
    image: "/images/others/CC.webp",
  },
  {
    image: "/images/others/Cherry.webp",
  },
  {
    image: "/images/others/FBL.webp",
  },
  {
    image: "/images/others/JSF.webp",
  },
  {
    image: "/images/others/PC.webp",
  },
  //   {
  //     image: "/images/brand/brand-7.png",
  //   },
  //   {
  //     image: "/images/brand/brand-8.png",
  //   },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-4 col-6" key={index}>
          <div className="brand-grid">
            <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
