import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const TermsOfUse = () => {
  return (
    <>
      <SEO title="Terms Of Use" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Terms Of Use" page="Terms Of Use" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <h3>Terms of Use for Black & White Media</h3>
                  <h5>Welcome to Black & White Media </h5>
                  <p>
                    By accessing and using the website and services of Black &
                    White Media ("we," "us," or "our"), you agree to comply with
                    and be bound by the following terms of use. Please review
                    these terms carefully. If you do not agree to these terms,
                    you should not use our services or access our website.
                  </p>
                  <h4>Use of Site</h4>
                  <p>
                    You are granted a non-exclusive, non-transferable, revocable
                    license to access and use the website and the resources
                    available for download from the website strictly in
                    accordance with these terms of use.
                  </p>
                  <h4>Intellectual Property Rights</h4>
                  <p>
                    All content on this site, including but not limited to text,
                    graphics, logos, images, as well as the compilation thereof,
                    and any software used on the site, is our property or the
                    property of our suppliers or licensors. You agree not to
                    reproduce, duplicate, copy, sell, resell or exploit any
                    portion of the site without express written permission from
                    us.
                    <br />
                    <a href="mailto:example@Black & White Media.com">
                      info@Black&WhiteMedia.com
                    </a>{" "}
                  </p>
                  <h4>User Conduct</h4>
                  <p>
                    You agree to use the site and the services only for lawful
                    purposes. You are prohibited from posting on or transmitting
                    through the site any material which violates or infringes in
                    any way upon the rights of others, which is unlawful,
                    threatening, abusive, defamatory, invasive of privacy or
                    publicity rights, vulgar, obscene, or otherwise
                    objectionable.
                  </p>
                  <h4>Third-Party Services</h4>
                  <p>
                    We may use third parties to provide certain services
                    accessible through the website. We do not control these
                    third parties or their services, and you agree that we will
                    not be liable to you in any way for your use of such
                    services. These third parties may have their own terms of
                    use and other policies. When you use these services, you
                    must comply with such terms and policies as well as these
                    terms of use.
                  </p>
                  <h4>Limitation of Liability</h4>
                  <p>
                    In no event will we or our directors, employees, or agents
                    be liable for any direct, indirect, consequential,
                    exemplary, incidental, special, or punitive damages,
                    including loss of profits, even if we have been advised of
                    the possibility of such damages.
                  </p>
                  <h4>Indemnification</h4>
                  <p>
                    You agree to indemnify, defend, and hold harmless Black &
                    White Media, its officers, directors, employees, agents,
                    licensors, and suppliers from and against all losses,
                    expenses, damages, and costs, including reasonable
                    attorneys' fees, resulting from any violation of these terms
                    of use or any activity related to your account (including
                    negligent or wrongful conduct).
                  </p>
                  <h4>Changes to Terms</h4>
                  <p>
                    We reserve the right, at our sole discretion, to change,
                    modify, add or remove portions of these terms at any time.
                    It is your responsibility to check these terms periodically
                    for changes. Your continued use of the site following the
                    posting of changes will mean that you accept and agree to
                    the changes.
                  </p>
                  <h4>Contact Us</h4>
                  <p>
                    If you have any questions or suggestions about our Terms of
                    Use, do not hesitate to contact us at
                    <br />
                    <a href="mailto:example@Black & White Media.com">
                      info@Black&WhiteMedia.com
                    </a>
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
