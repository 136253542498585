import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img
                src={process.env.PUBLIC_URL + "/images/others/BOE.webp"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">Who we are</span>
                <h2 className="title">
                  Building custom solutions for your business
                </h2>
                <p>
                  At Black & White Media, we're passionate about bringing your
                  vision to life with innovative media services and bespoke web
                  design. Our team dives deep into the heart of your business,
                  crafting tailor-made digital experiences that captivate your
                  audience and elevate your brand. With a blend of creativity
                  and technology, we're dedicated to delivering exceptional
                  results. From stunning websites that offer seamless user
                  experiences to comprehensive media strategies that amplify
                  your message, we empower your business to thrive in todays
                  digital landscape.
                </p>
                <p>
                  Let us help you stand out and succeed. Partner with us for
                  solutions that are as unique as your business.
                </p>
                <Link
                  to="/service-details/design"
                  className="axil-btn btn-fill-primary btn-large"
                >
                  View Services
                </Link>
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="10" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Years in the market</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? (
                            <CountUp end="1500" duration={1} />
                          ) : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Projects delivered</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
