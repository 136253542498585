import React from "react";
import FormOne from "../contact/FormOne";
import Accordion from "react-bootstrap/Accordion";
import { FaCompress, FaCode, FaGlobe } from "react-icons/fa";

const AboutTwo = () => {
  return (
    <div className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="why-choose-us">
              <div className="section-heading heading-left">
                {/* <span className="subtitle">About Us</span> */}
                <h3 className="title">Why branding matters?</h3>
                <p>
                  Branding is the heart of your company's identity. It's more
                  than just logos and colors; it's the emotional and
                  psychological connection your business makes with your
                  audience. A strong brand stands out in a densely crowded
                  marketplace, communicates what your company stands for, and,
                  most importantly, increases customer loyalty.
                </p>
              </div>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <FaCompress /> SEO & Marketing
                  </Accordion.Header>
                  <Accordion.Body>
                    Our strategy kicks off with a deep dive into your business,
                    market, and target audience, pinpointing the unique essence
                    of your brand. We then develop a customized strategy that
                    leverages the{" "}
                    <strong>
                      power of SEO and targeted pay-per-click (PPC) marketing
                    </strong>{" "}
                    on platforms like Facebook and Instagram. Our objective is
                    to not just position your brand effectively but to amplify
                    its visibility in search engine results and social media
                    spaces. By doing so, we aim to connect your brand with your
                    ideal audience more effectively, laying a robust foundation
                    for all subsequent marketing initiatives and driving
                    measurable results.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <FaCode /> Design
                  </Accordion.Header>
                  <Accordion.Body>
                    Design is the visual language of your brand. Our creative
                    team focuses on creating compelling and cohesive brand
                    identities that visually communicate your brand's essence.
                    From logos to color schemes and typography,{" "}
                    <strong>every element is meticulously designed</strong> to
                    reflect your brand's personality and appeal to your target
                    demographic.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <FaGlobe /> Development
                  </Accordion.Header>
                  <Accordion.Body>
                    In the digital age, your online presence is a crucial
                    component of your brand. Our development team specializes in
                    creating websites and{" "}
                    <strong>
                      digital solutions that are not only aesthetically pleasing
                      but also functional, user-friendly, and optimized for
                      search engines
                    </strong>
                    . We ensure your digital footprint embodies your brand,
                    enhances user experience, and drives engagement.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box shadow-box mb--30">
              <h3 className="title">
                Get A Free Quote <br /> Now
              </h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
