import React from "react";
import { Link, useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import { slugify } from "../utils";
import ProjectData from "../data/project/ProjectData.json";
import VideoOne from "../component/video/VideoOne";
import Accordion from "react-bootstrap/Accordion";
import { FaCompress, FaCode, FaGlobe } from "react-icons/fa";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const allProjectData = ProjectData;

const ProjectDetails = () => {
  const params = useParams();
  const projectSlug = params.slug;

  const getProjectData = allProjectData.filter(
    (data) => slugify(data.title) === projectSlug
  );
  const detailsProject = getProjectData[0];

  return (
    <>
      <SEO title="Project Details" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerTwo
          title={detailsProject.title}
          paragraph={detailsProject.excerpt}
          mainThumb={detailsProject.image}
        />
        <section className="section-padding single-portfolio-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">
                    {detailsProject.category.map((cat, i) => (
                      <span key={i}>{cat}</span>
                    ))}
                  </span>
                  <h3 className="title">{detailsProject.title}</h3>
                </div>
                {detailsProject.body.map((para, index) => (
                  <p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
                ))}
                <Link to="/contact" className="axil-btn btn-fill-primary">
                  Get it Now
                </Link>
              </div>
              <div className="col-lg-6 offset-xl-1">
                <div className="why-choose-us">
                  <div className="section-heading heading-left">
                    <h3 className="title">We delivered</h3>
                    <p>
                      Our company stands at the forefront of this digital
                      revolution, specializing in crafting bespoke websites and
                      implementing strategic SEO solutions. We empower
                      businesses to navigate the complexities of the digital
                      world, ensuring they not only stand out but also achieve
                      sustainable growth and enhanced online visibility. Let us
                      help you harness the power of digital technology to
                      redefine your online presence and connect with your
                      audience more effectively than ever before.
                    </p>
                  </div>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <FaCompress /> SEO & Marketing
                      </Accordion.Header>
                      <Accordion.Body>
                        Our strategy kicks off with a deep dive into your
                        business, market, and target audience, pinpointing the
                        unique essence of your brand. We then develop a
                        customized strategy that leverages the power of SEO and
                        targeted pay-per-click (PPC) marketing on platforms like
                        Facebook and Instagram. Our objective is to not just
                        position your brand effectively but to amplify its
                        visibility in search engine results and social media
                        spaces. By doing so, we aim to connect your brand with
                        your ideal audience more effectively, laying a robust
                        foundation for all subsequent marketing initiatives and
                        driving measurable results.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <FaCode /> Design
                      </Accordion.Header>
                      <Accordion.Body>
                        Design is the visual language of your brand. Our
                        creative team focuses on creating compelling and
                        cohesive brand identities that visually communicate your
                        brand's essence. From logos to color schemes and
                        typography, every element is meticulously designed to
                        reflect your brand's personality and appeal to your
                        target demographic.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <FaGlobe /> Development
                      </Accordion.Header>
                      <Accordion.Body>
                        In the digital age, your online presence is a crucial
                        component of your brand. Our development team
                        specializes in creating websites and digital solutions
                        that are not only aesthetically pleasing but also
                        functional, user-friendly, and optimized for search
                        engines. We ensure your digital footprint embodies your
                        brand, enhances user experience, and drives engagement.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <VideoOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectDetails;
