import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Home Pages Import
import CorporateAgency from "./pages/CorporateAgency";

// Service
import ServiceDetails from "./pages/ServiceDetails";

// Project
import ProjectGridOne from "./pages/ProjectGridOne";
import ProjectGridTwo from "./pages/ProjectGridTwo";
import ProjectGridThree from "./pages/ProjectGridThree";
import ProjectGridFive from "./pages/ProjectGridFive";
import ProjectGridFour from "./pages/ProjectGridFour";
import ProjectDetails from "./pages/ProjectDetails";

// Pages
import AboutUs from "./pages/AboutUs";
import OurClients from "./pages/OurClients";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

// Css Import
import "./assets/scss/app.scss";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          {/* Home */}
          <Route
            path={process.env.PUBLIC_URL + "/"}
            element={<CorporateAgency />}
          />

          {/* Service */}
          <Route
            path={process.env.PUBLIC_URL + "/service-details/:slug"}
            element={<ServiceDetails />}
          />

          {/* Project  */}
          <Route
            path={process.env.PUBLIC_URL + "/project-grid-one"}
            element={<ProjectGridOne />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/projects"}
            element={<ProjectGridTwo />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/project-grid-three"}
            element={<ProjectGridThree />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/project-width-one"}
            element={<ProjectGridFour />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/project-width-two"}
            element={<ProjectGridFive />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/project-details/:slug"}
            element={<ProjectDetails />}
          />

          {/* Pages  */}
          <Route
            path={process.env.PUBLIC_URL + "/about-us"}
            element={<AboutUs />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/our-clients"}
            element={<OurClients />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/contact"}
            element={<Contact />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/404"}
            element={<ErrorPage />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/privacy-policy"}
            element={<PrivacyPolicy />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/terms-use"}
            element={<TermsOfUse />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
